export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceProcessUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;

    static localeMappings: any = [];

	static grafanaConfiguration: {
		isEnabled: boolean;
		url: string;
		environmentName: string;
	} = {
		isEnabled: false,
		url: '',
		environmentName: ''
	};

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'SmartAccess'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token',
    };

    static readonly grid = {
        defaultPageSize: 10,
    };
    
    static readonly MaximumPhotoSize = 5242880;

    static readonly MinimumUpgradePaymentAmount = 1;

    /// <summary>
    /// Gets current version of the application.
    /// It's also shown in the web page.
    /// </summary>
    static readonly WebAppGuiVersion = '13.0.0';

    /// <summary>
    /// Redirects users to host URL when using subdomain as tenancy name for not existing tenants
    /// </summary>
    static readonly PreventNotExistingTenantSubdomains = false;
    
    static readonly RedStatusClass = 'red-status';
    static readonly GreenStatusClass = 'green-status';

    static readonly AppStatusHeaderTruckStatusChanged = 'app.status.header.truckStatusChanged';
    static readonly AppStatusHeaderDriverStatusChanged = 'app.status.header.driverStatusChanged';
    static readonly AppStatusHeaderReconnected = 'app.status.header.reconnected';
    static readonly AppStatusHeaderConnected = 'app.status.header.connected';
    static readonly AppStatusHeaderConnectingError = 'app.status.header.connectingError';
    static readonly AppStatusHeaderAlreadyConnected = 'app.status.header.alreadyConnected';
    static readonly AppStatusPreActivityReportReconnected = 'app.status.preActivityReport.reconnected';
    static readonly AppStatusPreActivityReportConnected = 'app.status.preActivityReport.connected';
    static readonly AppStatusPreActivityReportConnectingError = 'app.status.preActivityReport.connectingError';
    static readonly AppStatusPreActivityReportAlreadyConnected = 'app.status.preActivityReport.alreadyConnected';
    static readonly AppStatusPreActivityReportStatusChanged = 'app.status.preActivityReport.statusChanged';
    static readonly AppStatusPreActivityReportPhotoApprovalChanged = 'app.status.preActivityReport.photoApprovalChanged';
    static readonly AppStatusPreActivityReportCanceled = 'app.status.preActivityReport.canceled';
    static readonly AppStatusPreActivityReportTripDriverUpdated = 'app.status.preActivityReport.tripDriverUpdated';
    static readonly AppStatusPreActivityReportChangeToReport = 'app.status.preActivityReport.changeToReport';
    static readonly AppStatusPreActivityReportTurnExpired = 'app.status.preActivityReport.turnExpired';
    static readonly AppStatusPreActivityReportEdited = 'app.status.preActivityReport.edited';

    static readonly LocalStorageBrowserWebPushKey = "Smartruck_WebPushBrowserUID";
    static readonly UpmFbTenantId = 1;

    static readonly SignalRMaxTries = 20;
    static readonly SignalRReconnectTime = 5000;
    static appInsightsInstrumentationKey: string;

    static readonly MaxArticleCount = 30;
    static readonly MaxSerialLength = 2;
    static readonly CofusaRutParameterName = 'CofusaRUT';
    static readonly CofusaExternalIdParameterName = 'CofusaExternalId';

    static readonly CurrentTripPath = 'app/main/current-trip';
    static readonly EnterTruckPlatePath = 'app/main/enter-truck-plate'
    static readonly TakeTripPath = 'app/main/take-trip';
    static readonly CreateWoodReceiptPath = 'app/main/create-wood-receipt';
    static readonly CreateDiverseProductReceiptPath = 'app/main/diverse-product-receipt';
    static readonly CreateInternalMovementReceiptPath = 'app/main/internal-movement-receipt';
    static readonly CancelTripPath = 'app/main/cancel-trip';
    static readonly ReadReceiptQRPath = 'app/main/read-receipt-qr';
    static readonly WoodManualReceiptPath = 'app/main/wood-manual-receipt';
    
    static readonly PlantaFBPlaceId = 1;
    static readonly PlantaFBExternalId = 60728;
    static readonly DefaultParameterPlaceId = 1;

    static readonly AppName = 'SmartTruck';
	static readonly GrafanaAppName = this.AppName;
}
