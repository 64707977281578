import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AppConsts } from '../../shared/AppConsts';
import { SmartTruckMonitoringService } from './smart-truck-monitoring-service.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerServiceService implements ErrorHandler {

    constructor(private readonly injector: Injector) { }

    handleError(error: Error) {
        this.injector.get<SmartTruckMonitoringService>(SmartTruckMonitoringService).logException(error);
    }
}
