import { Injectable } from '@angular/core';
import { initializeFaro, getWebInstrumentations } from '@grafana/faro-web-sdk';
import { AppConsts } from '../../../shared/AppConsts';
import { environment } from 'environments/environment';

@Injectable()
export class FaroInitializerService {
  
  public static initialize(): void {
    const grafanaConfiguration = environment['grafana'];
    const isEnabled: boolean = grafanaConfiguration?.isEnabled ?? false;
    if (!isEnabled) {
      return;
    }
    
    const versionNumber = AppConsts.WebAppGuiVersion;
    const environmentName = grafanaConfiguration.environmentName;
    const grafanaUrl = grafanaConfiguration.url;
    const appNameForGrafana = AppConsts.AppName;

    initializeFaro({
      url: grafanaUrl,
      app: {
        name: appNameForGrafana,
        version: versionNumber,
        environment: environmentName,
      },
      instrumentations: [...getWebInstrumentations({ captureConsole: true, captureConsoleDisabledLevels: [] })],
    });
  }

}
