import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConsts } from '@shared/AppConsts';
import { faro } from '@grafana/faro-web-sdk';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmartTruckMonitoringService {

  appInsights: ApplicationInsights;
  constructor() {
    this.startMonitoring();
  }

  startMonitoring() {
    if (SmartTruckMonitoringService.isApplicationInsightsEnabled()) {
      const keyValue = AppConsts.appInsightsInstrumentationKey;
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: keyValue,
          enableAutoRouteTracking: true // log all route changes
        }
      });
      this.appInsights.loadAppInsights();
      this.appInsights.trackPageView();
    }
  }

  logPageView(name?: string, url?: string) { // call manually
    if (SmartTruckMonitoringService.isApplicationInsightsEnabled()) {
      this.appInsights.trackPageView({
        name: name,
        uri: url
      });
    }
    if (SmartTruckMonitoringService.isGrafanaEnabled()) {
      faro.api.pushEvent(
        'pageView', // Custom event name
        {
          name: name,
          url: url
        }
      );
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (SmartTruckMonitoringService.isApplicationInsightsEnabled()) {
      this.appInsights.trackEvent({ name: name}, properties);
    }
    if (SmartTruckMonitoringService.isGrafanaEnabled()) {
      faro.api.pushEvent(name);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (SmartTruckMonitoringService.isApplicationInsightsEnabled()) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
    if (SmartTruckMonitoringService.isGrafanaEnabled()) {
      faro.api.pushMeasurement({
        type: name,
        values: {
          duration: average,
        },
      });
    }
  }

  logException(exception: Error, severityLevel?: number) {
    console.error(exception);
    if (SmartTruckMonitoringService.isApplicationInsightsEnabled()) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
    if (SmartTruckMonitoringService.isGrafanaEnabled()) {
      faro.api.pushError(exception);
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    console.log(message);
    if (SmartTruckMonitoringService.isApplicationInsightsEnabled()) {
      this.appInsights.trackTrace({ message: message}, properties);
    }
    if (SmartTruckMonitoringService.isGrafanaEnabled()) {
      faro.api.pushLog([message]);
    }
  }

  private static isGrafanaEnabled() {
    return environment['grafana']?.isEnabled ?? false;
  }
  
  private static readonly isApplicationInsightsEnabled: () => boolean
    = () => !!AppConsts.appInsightsInstrumentationKey;
}
