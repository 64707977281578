// "Production" enabled environment

export const environment = {
    production: true,
    hmr: false,
    appConfig: 'config/appconfig.development.json',
    grafana: {
        isEnabled: true,
        url: 'https://faro-collector-prod-sa-east-1.grafana.net/collect/b669fa01543a10b807cf823fc6cd50ff',
        environmentName: 'development',
    }
};
